<template>
<div>
  <span>提交倒计时：</span>
<span class="time-box">{{ minutes }}</span>
:
<span class="time-box">{{seconds}}</span>
</div>

</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      countdownTime: 0,
      seconds: '00',
      minutes: '00'
    }
  },
  computed: {
    ...mapState({
      testEndTime: 'testEndTime'
    })
  },
  watch: {
    testEndTime: {
      handler (time) {
        if(!sessionStorage.getItem('testEndTime')) return
        this.countdownTime = (time - new Date().getTime()) / 1000
        if (this.countdownTime > 0) {
          this.countdown()
        }
      },
      immediate: true
    }
  },
  methods: {
    countdown () {
      window.clearTimeout(window.timering)

      if (!this.testEndTime) {
        return
      }
      if (this.countdownTime <= 0) {
        this.afterCountdown()
        return
      }
      // this.countdownTime--
      this.countdownTime = (this.testEndTime - new Date().getTime()) / 1000
      this.seconds =
        this.countdownTime % 60 < 10
          ? '0' + parseInt(this.countdownTime % 60)
          : parseInt(this.countdownTime % 60)
      this.minutes =
        Math.floor(this.countdownTime / 60) < 10
          ? '0' + Math.floor(this.countdownTime / 60)
          : Math.floor(this.countdownTime / 60)

      window.timering = setTimeout(() => {
        this.countdown()
      }, 1000)
    },
    afterCountdown () {
      this.$emit('afterCountdown')
    }
  }
}
</script>

<style lang="scss" scoped>
.time-box{

background: linear-gradient(0deg, #44CE8B 0%, #2CB774 100%);
padding: 0.2rem;
border-radius: 10%;
color: white;
}
</style>
