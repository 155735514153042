<template>
  <div>
    <div v-if="![6, 7, 8].includes(section.sectionType)">
      <div v-if="examQuestion.description" class="testinfor-title">
        {{ examQuestion.description }}
      </div>
      <div
        v-if="examQuestion.stemStatement"
        v-html="examQuestion.stemStatement"
        class="stem-statement"
      ></div>
      <EnglishTestQuestion
        v-for="question in examQuestion.simulationExamQuestions" 
        :examQuestion="question"
        :setionId="section.sectionId"
        @changeRadio="changeRadio"
        :key="question.questionId"
        :index="index"
        :answer="getOldAnswers(0,question.questionId)"
      />
    </div>
    <div class="chooseWords" v-if="[6, 7].includes(section.sectionType)">
      <div
        v-if="examQuestion.description"
        class="testinfor-title"
        v-html="examQuestion.description"
      ></div>
      <div
        v-if="examQuestion.stemStatement"
        class="stem-statement"
        v-html="examQuestion.stemStatement"
      ></div>
      <div
        v-for="question in examQuestion.simulationExamQuestions"
        :key="question.questionId"
        class="chooseWordsContent"
      >
        <div
          :class="question.questionId"
          ref="questionHtml"
          @input="getInput(index, question.questionId, section.sectionType)"
          v-html="question.statement"
        ></div>
      </div>
    </div>
    <div v-if="section.sectionType === 8">
      <div v-if="examQuestion.description" class="testinfor-title">
        {{ examQuestion.description }}
        （{{ examQuestion.sectionScore }}）
      </div>
      <div
        v-if="examQuestion.plyTkSectionId == 19"
        class="stem-statement"
        v-html="examQuestion.stemStatement"
      >
        <!-- {{ examQuestion.stemStatement }} -->
      </div>
      <div
        v-for="(
          question, questionIndex
        ) in examQuestion.simulationExamQuestions"
        :key="question.questionId"
      >
        <b-row cols="1">
          <b-col>
            <span>{{ question.variation + ". " }}</span>
            <span v-html="question.statement"></span>
          </b-col>
          <b-col cols="12" v-if="examQuestion.plyTkSectionId !== 20">
            <b-form-textarea
              :id="question.questionId"
              placeholder="请在此处输入答案"
              rows="5"
              :disabled="!isSelfScores[questionIndex]"
              @update="
                (value) => {
                  textareaChange(value, index, question.questionId, 0);
                }
              "
            ></b-form-textarea>
          </b-col>
          <b-col
            v-if="
              examQuestion.plyTkSectionId === 20
                ? !writeSelfScore
                : !isSelfScores[questionIndex]
            "
          >
            <div class="demonstration-text">
              <div class="text-left">参考答案：</div>
              <div class="text-left" v-html="question.standardAnswerKey"></div>
            </div>
          </b-col>
          <b-col v-if="examQuestion.plyTkSectionId === 20">
            <WirteAnswer
              :questionId="question.questionId"
              :sectionId="section.sectionId"
              :studentExamId="userAnswers.studentExamId"
              :isLock="writeSelfScore"
              @afterRefreshImg="afterRefreshImg"
            >
            </WirteAnswer>
          </b-col>
          <b-col
            v-if="
              examQuestion.plyTkSectionId === 20
                ? !writeSelfScore
                : !isSelfScores[questionIndex]
            "
            class="self-rating-class"
          >
            <span>自评得分：</span>
            <b-form-input
              type="number"
              class="self-rating"
              min="0"
              :max="question.questionScore"
              @update="
                (value) => {
                  textareaChange(
                    value,
                    index,
                    question.questionId,
                    1, 
                    question.questionScore
                  );
                }
              "
              @blur="
                (tg) => {
                  scoreCheck(tg, question.questionScore);
                }
              "
            ></b-form-input>
          </b-col>
          <b-col
            v-if="
              examQuestion.plyTkSectionId === 20
                ? writeSelfScore
                : isSelfScores[questionIndex]
            "
            class="self-rating-class"
          >
            <span class="self-evaluation" v-show="selfEvaluation && examQuestion.sectionScore == 25">自评得分：{{totalPrice}}  </span>  
            <b-button
              @click="
                openSelfScore(
                  questionIndex,
                  examQuestion.plyTkSectionId,
                  question.questionId
                )
              "
              >{{selfEvaluation && examQuestion.sectionScore == 25 ? "重新自评" : "自评得分"}}</b-button
            >
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import EnglishTestQuestion from "@/components/englinsh-test/english-test-question.vue";
import WirteAnswer from "@/views/english-mock/components/wirte-answer.vue";
export default {
  components: {
    EnglishTestQuestion,
    WirteAnswer,
  },
  props: {
    examQuestion: {
      default: function () {
        return {};
      },
    },
    section: {
      default: function () {
        return {};
      },
    },
    index: {
      default: "",
    },
    totalPrice: {
      default: "0"
    },
    isSelfScores: {
      default: function () {
        return [];
      },
    },
    writeSelfScore: {
      default: true,
    },
    selfEvaluation:{
      default:false
    },
    userAnswers: {
      default: function () {
        return {};
      },
    },
  },
  methods: {
    changeRadio(checked, questionId) {
      this.$emit("changeRadio", checked, questionId, this.section.sectionId);
    },
    getInput(index, questionId, sectionType) {
      this.$emit("getInput", index, questionId, sectionType);
    },
    textareaChange(value, index, questionId, type, score) {
      this.$emit("textareaChange", value, index, questionId, type, score);
    },
    afterRefreshImg() {
      this.$emit("afterRefreshImg");
    },
    scoreCheck(tg, questionScore) {
      this.$emit("scoreCheck", tg, questionScore);
    },
    openSelfScore(questionIndex, plyTkSectionId, questionId) {
      this.$emit("openSelfScore", questionIndex, plyTkSectionId, questionId);
    },
  },
};
</script>

<style lang="scss" scoped>
.self-rating-class {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  // .self-rating {
  //   width: 15%;
  // }
}
.self-evaluation {
  padding-right: 1rem;
}
.demonstration-text {
  // margin-left: 3rem;
  padding: 0.5rem;
  color: #2cb774;
}
.testinfor-title {
  font-weight: bold;
  font-size: 1.1429rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
  text-align: left;
  text-indent: 15px;
}
//阅读理解
.choose-wordsA {
  .testinfor-title {
    text-align: center;
  }
  .question-statement {
    padding-bottom: 1rem;
  }
  .mockTestContainer {
    padding: 0.3rem 15px;
  }
}
//书面表达
.written-expression {
  .written-expression-title {
    text-align: left;
  }
  //情景运用
  .stem-statement {
    text-indent: 15px;
  }
  & > div:nth-child(1) {
    .row-cols-1 { 
      .col {
        display: flex;
        text-indent: 15px;
        input {
          text-align: center;
        }
      }
    }
    .self-rating {
      width: 8%;
      text-align: center;
    }
    .self-rating-class {
      padding: 1rem;
      align-items: center;
      justify-content: flex-end;
    }
    textarea {
      width: 94%;
      background-color: #f0f0f0;
      font-weight: bold;
      letter-spacing: 1px;
      margin-left: 4rem;
    }
    .row-cols-1 {
      img {
        padding: 0 5px;
      }
    }
  }

  //作文
  #mocktest-coll7 {
    & > div:nth-child(2) {
      .row-cols-1 {
        .col {
          text-align: left;
        }
      }
    }
  }
  //范文
  .demonstration-text {
    margin-left: 3rem;
    padding: 0.5rem;
    color: #2cb774;
  }
}
</style>